<template>
    <div class="sub-status-card" v-if="store.person">
        <div class="sub-status-header" v-if="!store.person.pro">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M14 3.50003V7.00003C14 8.57753 13.6344 9.85753 13.1062 10.8838C13.0879 10.9199 13.061 10.951 13.0279 10.9744C12.9949 10.9978 12.9566 11.0128 12.9165 11.0182C12.8763 11.0235 12.8355 11.019 12.7974 11.0051C12.7594 10.9912 12.7253 10.9682 12.6981 10.9382L5.4075 2.91815C5.37479 2.88215 5.35328 2.8374 5.34563 2.78936C5.33797 2.74133 5.3445 2.6921 5.3644 2.64772C5.3843 2.60334 5.41672 2.56573 5.45768 2.53949C5.49864 2.51326 5.54636 2.49954 5.595 2.50003H13C13.2652 2.50003 13.5196 2.60538 13.7071 2.79292C13.8946 2.98046 14 3.23481 14 3.50003ZM3.37 2.16378C3.29481 2.08132 3.1942 2.02638 3.08418 2.00772C2.97416 1.98905 2.86105 2.00772 2.76287 2.06077C2.6647 2.11381 2.58708 2.19817 2.54239 2.30042C2.4977 2.40268 2.4885 2.51694 2.51625 2.62503C2.35988 2.71146 2.22952 2.8382 2.13873 2.99208C2.04794 3.14596 2.00004 3.32136 2 3.50003V7.00003C2 10.295 3.595 12.2919 4.93313 13.3869C6.37437 14.5657 7.80813 14.9663 7.87063 14.9825C7.95656 15.0059 8.04719 15.0059 8.13313 14.9825C8.19563 14.9663 9.6275 14.5657 11.0706 13.3869C11.2897 13.2064 11.4994 13.0147 11.6987 12.8125L12.63 13.8363C12.6739 13.8858 12.7272 13.9261 12.7868 13.9548C12.8464 13.9836 12.9111 14.0002 12.9772 14.0037C13.0432 14.0073 13.1094 13.9977 13.1717 13.9755C13.234 13.9533 13.2913 13.919 13.3403 13.8745C13.3893 13.83 13.4289 13.7762 13.4569 13.7163C13.485 13.6563 13.5008 13.5914 13.5036 13.5253C13.5064 13.4592 13.496 13.3932 13.473 13.3312C13.4501 13.2691 13.4151 13.2122 13.37 13.1638L3.37 2.16378Z"
                    fill="#52525C"
                />
            </svg>

            <p>Free</p>

            <button v-if="!store.person.pro" class="upgrade-btn">Upgrade</button>
        </div>

        <div class="sub-status-header pro-status" v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M13 2.5H3C2.73478 2.5 2.48043 2.60536 2.29289 2.79289C2.10536 2.98043 2 3.23478 2 3.5V7C2 10.295 3.595 12.2919 4.93313 13.3869C6.37437 14.5656 7.80813 14.9656 7.87063 14.9825C7.95656 15.0059 8.04719 15.0059 8.13313 14.9825C8.19563 14.9656 9.6275 14.5656 11.0706 13.3869C12.405 12.2919 14 10.295 14 7V3.5C14 3.23478 13.8946 2.98043 13.7071 2.79289C13.5196 2.60536 13.2652 2.5 13 2.5ZM10.855 6.85375L7.355 10.3538C7.30856 10.4002 7.25342 10.4371 7.19272 10.4623C7.13202 10.4874 7.06696 10.5004 7.00125 10.5004C6.93554 10.5004 6.87048 10.4874 6.80978 10.4623C6.74908 10.4371 6.69394 10.4002 6.6475 10.3538L5.1475 8.85375C5.05368 8.75993 5.00097 8.63268 5.00097 8.5C5.00097 8.36732 5.05368 8.24007 5.1475 8.14625C5.24132 8.05243 5.36857 7.99972 5.50125 7.99972C5.63393 7.99972 5.76118 8.05243 5.855 8.14625L7 9.29313L10.1462 6.14625C10.1927 6.09979 10.2479 6.06294 10.3086 6.0378C10.3692 6.01266 10.4343 5.99972 10.5 5.99972C10.5657 5.99972 10.6308 6.01266 10.6914 6.0378C10.7521 6.06294 10.8073 6.09979 10.8538 6.14625C10.9002 6.1927 10.9371 6.24786 10.9622 6.30855C10.9873 6.36925 11.0003 6.4343 11.0003 6.5C11.0003 6.5657 10.9873 6.63075 10.9622 6.69145C10.9371 6.75214 10.9002 6.8073 10.8538 6.85375H10.855Z"
                    fill="#F6BB1E"
                />
            </svg>
            <p>PRO License</p>
        </div>

        <div class="border-b border-[#3F3F46] h-[14px] w-full"></div>

        <div class="mt-3">
            <div class="sub-status-item">
                <CheckIcon v-if="store.person.pro" class="h-6 w-6 text-[#F6BB1E]" />
                <XMarkIcon v-else class="h-6 w-6 text-[#575758]" />
                <p><span>Active </span>Protection</p>
            </div>

            <div class="sub-status-item">
                <CheckIcon v-if="store.person.pro" class="h-6 w-6 text-[#F6BB1E]" />
                <XMarkIcon v-else class="h-6 w-6 text-[#575758]" />
                <p><span>557 </span>Data Brokers</p>
            </div>

            <div class="sub-status-item">
                <CheckIcon v-if="store.person.pro" class="h-6 w-6 text-[#F6BB1E]" />
                <XMarkIcon v-else class="h-6 w-6 text-[#575758]" />
                <p><span>169,327 </span>Custom Sites</p>
            </div>

            <div class="sub-status-item">
                <CheckIcon v-if="store.person.pro" class="h-6 w-6 text-[#F6BB1E]" />
                <XMarkIcon v-else class="h-6 w-6 text-[#575758]" />
                <p><span>0h 0m </span>Time Saved</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { CheckIcon } from '@heroicons/vue/24/outline';

const store = usePersonalStore();
</script>

<style lang="scss" scoped>
.sub-status-card {
    @apply mx-4 p-4 rounded-lg bg-[rgba(255,255,255,0.04)] shadow-sm;
    @apply bg-[#18181b];

    .upgrade-btn {
        @apply text-[#F6BB1E] text-right font-bold text-sm w-full hover:underline;
    }

    .sub-status-header {
        @apply flex items-center gap-2;

        p {
            @apply font-bold text-sm;
            color: #fff !important;
        }
    }

    .pro-status {
        p {
            @apply text-[#f6bb1e];
        }
    }

    .sub-status-item {
        @apply flex items-center gap-2;

        p {
            @apply text-[#fff];

            span {
                @apply font-bold;
            }
        }
    }
}
</style>
