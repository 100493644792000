<template>
    <NuxtLoadingIndicator />
    <DynamicDialog />

    <div>
        <personal-sidebar />

        <div class="nuxt-page-view" :class="{ mobile: isMobile }">
            <div class="2xl:max-w-[1200px] 2xl:mx-auto">
                <NuxtPage />
            </div>
        </div>
    </div>
</template>

<script setup>
import PersonalSidebar from '~/components/layout/PersonalSidebar/PersonalSidebar.vue';
import DynamicDialog from 'primevue/dynamicdialog';

const isMobile = ref(false);

// Check if we're on mobile/tablet
onMounted(() => {
    const checkMobile = () => {
        isMobile.value = window.innerWidth <= 1024;
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    onUnmounted(() => {
        window.removeEventListener('resize', checkMobile);
    });
});
</script>

<style lang="scss" scoped>
.nuxt-page-view {
    margin-left: 280px;
    transition: margin-left 0.3s ease-in-out;

    &.mobile {
        margin-left: 0;
    }

    @media screen and (max-width: 1024px) {
        margin-left: 0;
    }
}
</style>
